import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { environment } from '@env/environment';

@Component({
  selector: 'ui-drawer-search',
  templateUrl: './ui-drawer-search.component.html',
  styleUrls: ['./ui-drawer-search.component.css']
})


export class UiDrawerSearchComponent implements OnInit, AfterViewInit
{


    constructor(private router: Router, private route: ActivatedRoute,
        private facadeCore: FacadeCoreService, private http: HttpClient)
    {

    }

    ngOnInit(): void
    {
    }

    ngAfterViewInit()
    {

    }

    challenge()
    {
        this.facadeCore.spinnersShow('spinner2');
        this.facadeCore.spinnersShow('spr-filters');

        this.http.get(environment.urlApi + '/ui/translations').subscribe(
        {
            next: (data: any) =>
            {
                this.facadeCore.spinnersHide('spinner2');
                this.facadeCore.spinnersHide('spr-filters');

            },
            complete: () =>
            {
                this.router.navigate(['corporative']);
            },
            error: (err: any) => { console.error(err); },
        });
    }

}
