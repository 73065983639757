import { Injectable } from '@angular/core';
import { ActivationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })


export class HttpCancelCoreService
{
  private isLoadApp : boolean;
  private pendingHttpRequests: Subject<any>;


  constructor(private router: Router)
  {
    this.isLoadApp  = false;
    this.pendingHttpRequests = new Subject<void>();

    this.init();
  }


  private init()
  {
    this.subscriptionRouter();
  }


  private subscriptionRouter()
  {
    this.router.events.subscribe(event =>
    {
      if (event instanceof ActivationEnd)
      {
        this.canceledPendingHttpRequests();
      }
    });
  }


  public canceledPendingHttpRequests(): void
  {
    /* console.log('router.events cancelPendingHttpRequests:: ' + this.isLoadApp); */
    if(this.isLoadApp) this.pendingHttpRequests.next(this.isLoadApp);
  }


  public cancelPendingHttpRequests(): Observable<any>
  {
    return this.pendingHttpRequests.asObservable();
  }


  /**
   *
   */
  public set isLoadComplete(bln:boolean)
  {
    this.isLoadApp = bln;
  }


  /**
   *
   */
  public get isLoadComplete(): boolean
  {
    return this.isLoadApp;
  }
}
