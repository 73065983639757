import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })


/**
 *
 */
export class GlobalService
{


  /**
   *
   */
  constructor()
  {

  }


  /**
   *
   */
  public get window() : any
  {
    return window;
  }


}
