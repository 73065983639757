import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })


export class breadcrumbCoreService
{

    private breadcrumb: BehaviorSubject<any[]>;


    constructor(private router: Router, private activatedRoute: ActivatedRoute)
    {
        this.breadcrumb = new BehaviorSubject<any[]>([]);
    }


    public init()
    {
        this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd), distinctUntilChanged()).subscribe((val: any) =>
        {
            var breadcrumb = this.createBreadcrumb(this.activatedRoute.root);

            breadcrumb.unshift({ label: 'home', url: '/' });

            this.breadcrumb.next(Array.from(new Set(breadcrumb.map((a: any) => a.label))).map((label: any) =>
            {
                return breadcrumb.find((a: any) => a.label === label);
            }));

        });
    }


    private createBreadcrumb(activatedRoute: ActivatedRoute, url: string = '', breadcrumbs: Array<any> = []): any
    {
        const children: ActivatedRoute[] = activatedRoute.children;

        if (children.length === 0) return breadcrumbs;

        for (const child of children)
        {
            const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
            const label: string = child.snapshot.data['breadcrumb'].label;

            if (routeURL !== '') url += ('/' + routeURL);

            breadcrumbs.push( { label: label, url: url });

            return this.createBreadcrumb(child, url, breadcrumbs);
        }
    }


    public update(): Observable<any>
    {
        return this.breadcrumb.asObservable();
    }

}


/* console.log(JSON.stringify(this.breadcrumbList)); */
/* this.breadcrumb.next(this.breadcrumbList); */


/* console.log(this.router.routerState.snapshot.root.url); */
/*  console.log(this.activatedRoute.firstChild); */


/* this.breadcrumbList.push(
{
    label: activatedRoute.snapshot.data['breadcrumb'].label,
    url: activatedRoute.snapshot.data['breadcrumb'].url
}); */


/*      var _parent = activatedRoute.parent;

     if (_parent)
     {
         do
         {
             var url = _parent.snapshot.url.toString();
             var data = _parent.snapshot.data['breadcrumb'];

             console.log(url + ' - ' + data);

             if (data)
             {
                     this.breadcrumbList.push({ label: data.label, url: data.url });
             }

             _parent = _parent.parent;

         } while (_parent);
     }

     this.breadcrumbList.push({ label: 'home', url: '/' });
     this.breadcrumbList.reverse();


     console.log(JSON.stringify(this.breadcrumbList));
     console.log('this.breadcrumb.next');
     this.breadcrumb.next(Array.from(new Set(this.breadcrumbList.map(a => a.label))).map(label =>
     {
         return this.breadcrumbList.find(a => a.label.toLowerCase() === label.toLowerCase());
     })); */
