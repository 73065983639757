import { StatusBar, Style } from '@capacitor/status-bar';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { UiDrawerNotificationsComponent } from './core/components/ui-drawer-notifications/ui-drawer-notifications.component';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';


import { ServiceWorkerModule } from '@angular/service-worker';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { UiBackdropComponent } from '@app/core/components/ui-backdrop/ui-backdrop.component';
import { UiDrawerAccountComponent } from '@app/core/components/ui-drawer-account/ui-drawer-account.component';
import { UiDrawerNavComponent } from '@app/core/components/ui-drawer-nav/ui-drawer-nav.component';
import { UiDrawerSearchComponent } from '@app/core/components/ui-drawer-search/ui-drawer-search.component';
import { UiDrawerShoppingComponent } from '@app/core/components/ui-drawer-shopping/ui-drawer-shopping.component';
import { FadeAnimationGuard } from '@app/core/guards/fade-animation.guard';
import { DEFAULT_TIMEOUT, HttpAppCoreInterceptor } from '@app/core/interceptors/http-app-core.interceptor';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { SharedCoreModule } from '@app/core/shared-core.module';
import { FacadeService } from '@app/services/facade.service';
import { environment } from '../environments/environment';

import { OverlayModule } from '@angular/cdk/overlay';
import { WebcamModule } from 'ngx-webcam';


@NgModule(
{
    declarations:
    [
        AppComponent,
        UiBackdropComponent,
        UiDrawerNavComponent,
        UiDrawerSearchComponent,
        UiDrawerAccountComponent,
        UiDrawerNotificationsComponent,
        UiDrawerShoppingComponent
    ],

    imports:
    [
        BrowserModule,
        IonicModule.forRoot(),
        SharedCoreModule,
        OverlayModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ScrollingModule,
        FormsModule,
        WebcamModule,
        ServiceWorkerModule.register('ngsw-worker.js',
        {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],

    providers:
    [
        FadeAnimationGuard,
        FacadeCoreService,
        FacadeService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: APP_INITIALIZER, useFactory: function (facadeCore: FacadeCoreService, platform: Platform)
            {
                return () =>
                {
                    return facadeCore.uiTranslate().then(() =>
                    {
                        return platform.ready().then(async() =>
                        {
                            if(!platform.is('desktop'))
                            {
                                facadeCore.window.screen.orientation.lock('portrait');

                                StatusBar.setStyle({ style: Style.Light });
                                StatusBar.setBackgroundColor({ color: "#ffffff" });
                            }

                            console.log('APP_INITIALIZER', facadeCore.window.screen.orientation);
                        });
                    });
                };
            },
            multi: true, deps: [FacadeCoreService, Platform]
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpAppCoreInterceptor, multi: true, },
        { provide: DEFAULT_TIMEOUT, useValue: 30000 }
    ],

    bootstrap : [AppComponent],
    schemas   : [CUSTOM_ELEMENTS_SCHEMA],
})


export class AppModule { }
