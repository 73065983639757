import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })


export class StorageCoreService
{
  constructor()
  {
    /* sessionStorage.removeItem('token'); */
  }


  /**
   *
   */
  public setTokenAccess(str: string)
  {
    /* sessionStorage.token = str; */
  }


  /**
   *
   */
  public getTokenAccess(): boolean
  {
    /* return sessionStorage.token; */
    return false;
  }

}
