import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fadeAnimation } from '@app/core/animations/fade-animation';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { FacadeService } from '@app/services/facade.service';
import { Platform } from '@ionic/angular';

@Component(
    {
        selector: 'app-root',
        templateUrl: './app.component.html',
        styleUrls: ['./app.component.css'],
        animations: [fadeAnimation]
    })


export class AppComponent implements AfterViewInit
{
    public title = 'app-ng-un';
    public isLogging = true;


    constructor(private facadeCore: FacadeCoreService, private facade: FacadeService, private http: HttpClient, private router: Router, private activatedRoute: ActivatedRoute, public platform: Platform)
    {
        this.initializeApp();
    }


    ngAfterViewInit()
    {
        this.facadeCore.breadcrumbInit();
    }


    private initializeApp()
    {
        this.platform.ready().then(async () =>
        {
            console.log('APP the platform is ready');
        });

        // document.addEventListener("deviceready", () => { }, false);
    }

}
