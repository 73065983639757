
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadModulesStrategyService } from '@app/core/services/preload-modules-strategy.service';


const routes: Routes =
    [
        {
            path: 'signin',
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers: 'always',
            loadChildren: () => import('@app/core/components/ui-signin/signin.module') .then(m => m.SigninModule),
            data: { preloadModuleStrategy: true, breadcrumb: 'signin' }
        },
        {
            path: 'analytics',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers: 'always',
            loadChildren: () => import('@app/pages/analytics/analytics.module') .then(m => m.AnalyticsModule),
            data:
            {
                preloadModuleStrategy: true,
                breadcrumb: { label: 'Analíticas' }
            }
        },
        {
            path: 'store',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers: 'always',
            loadChildren: () => import('@app/pages/store/store.module') .then(m => m.StoreModule),
            data:
            {
                preloadModuleStrategy: true,
                breadcrumb: { label: 'Store' }
            }
        },
        {
            path: 'corporative',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers: 'always',
            loadChildren: () => import('@app/pages/corporative/corporative.module') .then(m => m.CorporativeModule),
            data:
            {
                preloadModuleStrategy: true,
                breadcrumb: { label: 'Corporative' }
            }
        },
        {
            path                   :  'orders',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers  :  'always',
            loadChildren           :  ()=> import('@app/pages/roninwear/adm/orders/orders.module')
                                    .then(m =>  m.OrdersModule),
            data:
            {
                preloadModuleStrategy : true,
                breadcrumb: { label: 'Orders' }
            }
        },
        {
            path                   :  'order-preparation',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers  :  'always',
            loadChildren           :  ()=> import('@app/pages/roninwear/adm/order-preparation/order-preparation.module')
                                    .then(m =>  m.OrderPreparationModule),
            data:
            {
                preloadModuleStrategy : true,
                breadcrumb: { label: 'Order preparation' }
            }
        },
        {
            path                   :  'order-edit/:orderId',
            /*  canActivate            :  [ AuthCoreGuard ], */
            /* canDeactivate          :  [ FadeAnimationGuard ], */
            runGuardsAndResolvers  :  'always',
            loadChildren           :  ()=> import('@app/pages/roninwear/adm/order-edit/order-edit.module')
                                    .then(m =>  m.OrderEditModule),
            data:
            {
                preloadModuleStrategy : true,
                breadcrumb: { label: 'Order edit' }
            }
        },
        {
            path: '',
            redirectTo: 'signin',
            pathMatch: 'full',
        },
        {
            path: '**',
            redirectTo: 'signin',
            pathMatch: 'full',
        },
    ];

@NgModule(
    {
        imports:
            [
                RouterModule.forRoot(routes,
                    {
                        anchorScrolling: 'enabled',
                        enableTracing: false,
                        onSameUrlNavigation: 'reload',
                        preloadingStrategy: PreloadModulesStrategyService,
                        scrollPositionRestoration: 'disabled',
                        initialNavigation: 'enabledBlocking',
                        paramsInheritanceStrategy: 'always'
                    })
            ],

        exports: [RouterModule]
    })


export class AppRoutingModule { }
