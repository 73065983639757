import { Injectable, Injector } from '@angular/core';
import { GlobalService } from '@app/core/services/global.service';


@Injectable({ providedIn: 'root' })

export class FacadeService
{
  private _globalService: GlobalService;
  public get globalService(): GlobalService
  {
    if (!this._globalService) this._globalService = this.injector.get(GlobalService);

    return this._globalService;
  }


  constructor(private injector: Injector)
  {
    this._globalService = this.globalService;
  }


  public get window() : any
  {
    return this.globalService.window;
  }
}
