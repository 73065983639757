import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class FadeAnimationGuard implements CanDeactivate<any>
{


    constructor(private facadeCore: FacadeCoreService) { }


    canDeactivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
    {
        return new Observable<boolean>((observer: any) =>
        {
            observer.next(true);
            observer.complete();
        });
    }

}
