import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';


@Injectable({ providedIn: 'root' })


export class TranslateCoreService
{
    private trans: any;


    constructor(private http: HttpClient)
    {
        this.trans = [];
    }


    public uiTranslate()
    {
        return new Promise<void>((resolve, reject) =>
        {
            this.http.get<any>(environment.urlApi + "/ui/translations").subscribe(
            {
                next     : (data: any) => { this.trans = data; },
                complete : ()          => { resolve(); },
                error    : (err: any)  => { reject(err); },
            });
        });
    }


    public get translate()
    {
        return ((this.trans) ? this.trans : []);
    }
}
