<ui-backdrop></ui-backdrop>
<ui-drawer-nav [id]="'ui-drawer-nav'" [class]="'ui-drawer'"></ui-drawer-nav>
<ui-drawer-search [id]="'ui-drawer-search'" [class]="'ui-drawer'"></ui-drawer-search>
<ui-drawer-account [id]="'ui-drawer-account'" [class]="'ui-drawer'"></ui-drawer-account>
<ui-drawer-notifications [id]="'ui-drawer-notifications'" [class]="'ui-drawer'"></ui-drawer-notifications>
<ui-drawer-shopping [id]="'ui-drawer-shopping'" [class]="'ui-drawer'"></ui-drawer-shopping>


<div id="ui-page" class="ui-page" [@routeAnimation]="outlet && outlet.activatedRouteData">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
