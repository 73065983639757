import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { FacadeCoreService } from '@app/core/services/facade-core.service';


@Directive({ selector: '[uxRipple]' })
export class ClickDirective
{
    @Output('uxRipple') uxRipple: EventEmitter<any> = new EventEmitter();
    private timer: any;


    constructor(private facadeCore: FacadeCoreService) { }


    @HostListener('touchstart', ['$event']) onTouchStart(event: Event): void
    {
        console.log('Directive Ripple onTouchStart');
        this.click(event);
    }


    @HostListener('click', ['$event']) onClick(event: Event): void
    {
        console.log('Directive Ripple onClick');
        this.click(event);
    }


    /**
     *
     * @param event
     */
    private click(event: Event): void
    {
       /*  event.preventDefault();
        event.stopPropagation(); */

        clearTimeout(this.timer);
        this.timer = setTimeout(() =>
        {
            this.facadeCore.window.ux.ripple.launchOut = 0;
            this.uxRipple.next(event);

            console.log('Directive Ripple next ' + this.facadeCore.window.ux.ripple.launchOut);
        }, this.facadeCore.window.ux.ripple.launchOut);
    }

}
