import { Component, OnInit } from '@angular/core';

@Component(
{
  selector    : 'ui-backdrop',
  templateUrl : './ui-backdrop.component.html',
  styleUrls   : [ './ui-backdrop.component.css' ],
  host        : { 'id': 'ui-backdrop' }
})


export class UiBackdropComponent implements OnInit
{

  constructor() { }

  ngOnInit() { }

}
