import { isPlatformServer } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { StateKey, TransferState, makeStateKey } from '@angular/platform-browser';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { Observable, of, timer } from 'rxjs';
import { catchError, delay, finalize, retry, switchMap, takeUntil, tap, timeout } from 'rxjs/operators';


export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

/* export const DEFAULT_TIMEOUT = 3000; */

@Injectable({ providedIn: 'root' })


export class HttpAppCoreInterceptor implements HttpInterceptor
{
  constructor(private facadeCore: FacadeCoreService,
    private transferState: TransferState,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    @Inject(PLATFORM_ID) private platformId: any)
  {
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {


    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    const stateKey: StateKey<string> = makeStateKey<string>(request.url);

    /* console.log('TRANS', request.url); */

    const req = request.clone(
      {
        setHeaders:
        {
          'Accept-Language'        : navigator.language,
          'Authorization'          : 'dsgfag',
          'Clear-Site-Data'        : '"cache", "cookies"',
          'Content-Type'           : 'application/json',
          'X-Content-Type-Options' : 'nosniff',
          'X-localization'         : 'es',
          'X-platform'             : navigator.userAgent,
          'X-Requested-With'       : 'XMLHttpRequest',
        }
      });


    if (isPlatformServer(this.platformId))
    {
      // serverSide
      /* console.log('URL SERVER', JSON.stringify(req)); */

      return next.handle(req).pipe(timeout(timeoutValueNumeric), tap((event) =>
      {
        this.transferState.set(stateKey, (event as HttpResponse<any>).body);
      }),

        catchError((err: any) =>
        {
          this.facadeCore.canceledPendingHttpRequests();
          /* this.facadeCore.spinnerError(); */

          if (err.status === 401)
          {
            console.log('ERROR ' + err.status + ': ' + err.message);
            /* this.facadeCore.authSetIsLogging(false); */
          }

          return new Observable<HttpEvent<any>>();
        }),
        finalize(() =>
        {
          /* this.facadeCore.spinnerHide(); */
        }));

    }


    else
    {
      const transferStateResponse = this.transferState.get<any>(stateKey, null);
      /* this.facadeCore.window.ux.loop(); */

      if (transferStateResponse)
      {
        /* this.facadeCore.window.ux.loop(); */

        /* console.log('storedResponse'); */
        const response = new HttpResponse({ body: transferStateResponse, status: 200 });
        this.transferState.remove(stateKey);

        /* this.facadeCore.spinnerHide(); */
        return of(response);

      } else
      {
        /* this.facadeCore.window.ux.loop(); */

        /* console.log('INT :: ' + JSON.stringify(request.params.get('category'))) */
/* this.facadeCore.spinnerShow(); */
       /*  console.log('URL BROWSER', JSON.stringify(req)); */
        return  timer(0).pipe(switchMap(() =>
          {

           return next.handle(req).pipe(retry(0),timeout(timeoutValueNumeric),
              takeUntil(this.facadeCore.cancelPendingHttpRequests().pipe(
                delay(Math.random() * 0),
                tap((e) =>
                {
                  /* this.facadeCore.spinnerReset(); */
                }))),
              catchError((err: any) =>
              {
                this.facadeCore.canceledPendingHttpRequests();
                /* this.facadeCore.spinnerError(); */
                console.log('ERROR ' + err.status + ': ' + err.message);
                console.log('catchError' + JSON.stringify(request.params.get('category')))
                if (err.status === 401)
                {
                  console.log('ERROR ' + err.status + ': ' + err.message);
                  /* this.facadeCore.authSetIsLogging(false); */
                }

                return new Observable<HttpEvent<any>>();
              }),
              finalize(() =>
              {
                /* console.log('finalize :: ' + JSON.stringify(request.params.get('category'))) */
                /* this.facadeCore.spinnerHide(); */


                 /* this.facadeCore.window.ux.loop(); */
              }));

          } ))

          ;
      }
    }


  }
}
