import { animate, animateChild, group, query, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('routeAnimation',
[
    transition('* <=> *',
    [
        query(':enter',
        [
            style(
            {
                position : 'absolute',
                zIndex   : 1,
                opacity  : 0
            })
        ],
        { optional: true }),

        group(
        [
            query(':leave',
            [
                style(
                {
                    position : 'absolute',
                    zIndex   : 0,
                    opacity  : 1
                }),
            ],
            { optional : true }),

            query(':enter',
            [
                style(
                {
                    position : 'absolute',
                    zIndex   : 1,
                    opacity  : 0
                }),
                animate('512ms cubic-bezier(0.2, 0, 0, 1)', style({ opacity : 1 })),
                animateChild()
            ],
            { optional : true,  delay: 0 })
        ])
    ])
]);
