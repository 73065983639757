

<div id="ui-filters" class="ui-block">

  <form>
    <div id="ui-filter-search" class="ui-block">
      <div class="form-wrapper">
        <div class="form-content">
          <label>
            <span class="form-icon-left">
              <i class="mat-icon">search</i>
            </span>
            <input type="text" class="form-el" placeholder="Buscar" autocomplete="off" />
          </label>
        </div>
      </div>
      <button type="reset" class="form-el ui-btn ux-ripple" data-ux-ripple="--ui-color-failure">
        <span>
          <i class="mat-icon">settings_backup_restore</i>
        </span>
      </button>
    </div>


    <div id="ui-filter-options" class="ui-scroll ">


      <!--   <div class="ui-block ui-nav-subhead-hr">
          <hr class="ui-block">
        </div>
        <div class="ui-block ui-nav-subhead">
          <span class="ui-nav-subhead-icon"> <i class="mat-icon light">language</i> </span>
          <span class="ui-block ellipsis fs-medium bold light" translate>LOADING</span>
        </div> -->


      <!--  <div class="ui-block ui-nav-subhead-hr">
          <hr class="ui-block">
        </div> -->
      <div class="ui-block ui-nav-subhead">
        <span class="ui-nav-subhead-icon"> <i class="mat-icon light">accessibility_new</i> </span>
        <span class="ui-block ellipsis fs-medium bold light">Género</span>
      </div>


      <div class="form-wrapper form-wrapper-inline">
        <div class="form-content form-content-check">
          <label for="check-0">
            <input type="checkbox" id="check-0" name="check-0" />
            <span class="form-icon-left">
              <span class="ux-ripple" data-ux-ripple="--ui-color-form"></span>
              <i class="mat-icon"></i>
            </span>
            <span class="form-el">
              <span class="ellipsis">Check Box</span>
            </span>
          </label>
        </div>

        <div class="form-content form-content-check">
          <label for="check-1">
            <input type="checkbox" id="check-1" name="check-1" />
            <span class="form-icon-left">
              <span class="ux-ripple" data-ux-ripple="--ui-color-form"></span>
              <i class="mat-icon"></i>
            </span>
            <span class="form-el">
              <span class="ellipsis">Check Box</span>
            </span>
          </label>
        </div>
      </div>


      <div class="ui-block ui-nav-subhead-hr">
        <hr class="ui-block">
      </div>
      <div class="ui-block ui-nav-subhead">
        <span class="ui-nav-subhead-icon"> <i class="mat-icon light">local_shipping</i> </span>
        <span class="ui-block ellipsis fs-medium light bold light">Transporte</span>
      </div>
      <div class="form-wrapper form-wrapper-inline">
        <div class="form-content form-content-check">
          <label for="radio-0">
            <input type="radio" id="radio-0" name="radio" />
            <span class="form-icon-left">
              <span class="ux-ripple" data-ux-ripple="--ui-color-form"></span>
              <i class="mat-icon"></i>
            </span>
            <span class="form-el">
              <span class="ellipsis">Radio</span>
            </span>
          </label>
        </div>

        <div class="form-content form-content-check">
          <label for="radio-1">
            <input type="radio" id="radio-1" name="radio" />
            <span class="form-icon-left">
              <span class="ux-ripple" data-ux-ripple="--ui-color-form"></span>
              <i class="mat-icon"></i>
            </span>
            <span class="form-el">
              <span class="ellipsis">Radio</span>
            </span>
          </label>
        </div>
      </div>


      <div class="ui-block ui-nav-subhead-hr">
        <hr class="ui-block">
      </div>
      <div class="ui-block ui-nav-subhead">
        <span class="ui-nav-subhead-icon"> <i class="mat-icon light">language</i> </span>
        <span class="ui-block ellipsis fs-medium bold light">Geografía</span>
      </div>


      <div class="form-wrapper form-wrapper-inline">
        <div class="form-content">
          <label for="selectx-00">
            <span class="form-icon-right"> <i class="mat-icon">arrow_drop_down</i> </span>
            <select class="form-el" name="selectx-00" id="selectx-00" required>
              <option>Select</option>
            </select>
            <hr>
            <span class="form-title fs-small light ellipsis">País</span>
            <span class="form-help">
              <span class="form-required fs-x-small ellipsis">* Required</span>
              <span class="form-helper fs-x-small ellipsis">Helper</span>
            </span>
          </label>
        </div>

        <div class="form-content">
          <label for="selectx-01">
            <span class="form-icon-right"> <i class="mat-icon">arrow_drop_down</i> </span>
            <select class="form-el" name="selectx-01" id="selectx-01" required>
              <option>Select</option>
            </select>
            <hr>
            <span class="form-title fs-small light ellipsis">País</span>
            <span class="form-help">
              <span class="form-required fs-x-small ellipsis">* Required</span>
              <span class="form-helper fs-x-small ellipsis">Helper</span>
            </span>
          </label>
        </div>
      </div>


      <div class="ui-block ui-nav-subhead-hr">
        <hr class="ui-block">
      </div>
      <div class="ui-block ui-nav-subhead">
        <span class="ui-nav-subhead-icon"> <i class="mat-icon light">category</i> </span>
        <span class="ui-block ellipsis fs-medium bold light">Composición</span>
      </div>


      <div class="form-wrapper">
        <div class="form-content">
          <label for="select-0">
            <span class="form-icon-left"> <i class="mat-icon">colorize</i> </span>
            <span class="form-icon-right"> <i class="mat-icon">arrow_drop_down</i> </span>
            <select class="form-el" id="select-0" name="select-0">
              <option>Select</option>
            </select>
            <hr>
            <span class="form-title fs-small light ellipsis">Color</span>
            <span class="form-help fs-x-small light ellipsis">Seleccione un color</span>
          </label>
        </div>
      </div>
      <div class="form-wrapper">
        <div class="form-content">
          <label for="select-1">
            <span class="form-icon-left"> <i class="mat-icon">colorize</i> </span>
            <span class="form-icon-right"> <i class="mat-icon">arrow_drop_down</i> </span>
            <select class="form-el" id="select-1" name="select-1">
              <option>Select</option>
            </select>
            <hr>
            <span class="form-title fs-small light ellipsis">Color</span>
            <span class="form-help fs-x-small light ellipsis">Seleccione un color</span>
          </label>
        </div>
      </div>

      <div class="ui-block spr-btn">
        <ux-spinners id="spr-filters" delay="0" [class]="'ux-el-in-viewport'">
          <div class="form-wrapper form-wrapper-button">
            <div class="form-content form-content-button form-content-button">
              <label class="ux-ripple" data-ux-ripple="--ui-colors-neutral-white">
                <span class="form-icon-left"> <i class="mat-icon">filter_list</i> </span>
                <button class="form-el" type="button" data-ux-ripple="--ui-colors-neutral-white" (uxRipple)="challenge()">
                  <span class="fs-small bold ellipsis">Aplicar Filtros</span>
                </button>
              </label>
            </div>
          </div>
        </ux-spinners>
      </div>

      <div class="form-wrapper form-wrapper-textarea">
        <div class="form-content">
          <label for="textarea-0">
            <span class="form-icon-left"> <i class="mat-icon">notes</i> </span>
            <textarea class="form-el" name="textarea-0" id="textarea-0"
              required>Minim magna anim consectetur officia laborum qui dolor officia incididunt.</textarea>
            <hr>
            <span class="form-title fs-small light ellipsis">País</span>
            <span class="form-help">
              <span class="form-required fs-x-small ellipsis">* Required</span>
              <span class="form-helper fs-x-small ellipsis">Helper</span>
            </span>
          </label>
        </div>

      </div>

      <!--      <div class="ui-block ui-nav-subhead-hr">
          <hr class="ui-block">
        </div> -->
    </div>

  </form>
  <div class="ui-block">
    <ux-spinners id="spr-filters" delay="0" [class]="'spr-btn ux-el-in-viewport'">
      <div id="ui-filter-action" class="ui-block">
        <div class="form-wrapper form-wrapper-button">
          <div class="form-content form-content-button">
            <label>
              <span class="form-icon-left"> <i class="mat-icon">filter_list</i> </span>
              <button class="form-el ux-ripple" type="button" data-ux-ripple="--ui-colors-neutral-white" (uxRipple)="challenge()">
                <span class="bold ellipsis">Aplicar Filtros</span>
              </button>
            </label>
          </div>
        </div>
      </div>
    </ux-spinners>
  </div>
</div>
