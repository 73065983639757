import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FacadeCoreService } from '@app/core/services/facade-core.service';
import { environment } from '@env/environment';

@Component({
  selector    : 'ui-drawer-notifications',
  templateUrl : './ui-drawer-notifications.component.html',
  styleUrls   : ['./ui-drawer-notifications.component.css'],
  host        : { 'id': 'ui-drawer-notifications', 'class': 'ui-drawer ux-el-in-viewport' }
})
export class UiDrawerNotificationsComponent implements OnInit, OnDestroy, AfterViewInit
{
    public navigation: any;
    public sub: any;


    constructor(private facadeCore: FacadeCoreService, private http: HttpClient)
    {

    }


    ngOnInit()
    {
    }


    ngAfterViewInit()
    {
        this.launch();
    }


    launch()
    {
        this.facadeCore.spinnersShow('spinner-notifications');

        this.http.get(environment.urlApi + '/ui/navigation/account').subscribe(
        {
            next: (data: any) =>
            {
                this.navigation = data;
            },
            complete: () =>
            {
                this.facadeCore.spinnersHide('spinner-notifications');
            },
            error: (err: any) => { console.error(err); },

        });
    }


    ngOnDestroy()
    {

    }
}
