import { Injectable } from '@angular/core';
import { UxSpinnersComponent } from '@app/core/components/ux-spinners/ux-spinners.component';


@Injectable({ providedIn: 'root' })
export class SpinnersCoreService
{

    private spinnerCache = new Set<UxSpinnersComponent>();


    constructor() { }


    public _register(spinner: UxSpinnersComponent): void
    {
        this.spinnerCache.add(spinner);
    }


    public _unregister(spinnerToRemove: UxSpinnersComponent): void
    {
        this.spinnerCache.forEach(spinner =>
        {
            if (spinner === spinnerToRemove)
            {
                this.spinnerCache.delete(spinner);
            }
        });
    }


    public show(spinnerId: string): void
    {
        this.spinnerCache.forEach(spinner =>
        {
            if (spinner.id === spinnerId)
            {
                spinner.setShow = true;
            }
        });
    }


    public hide(spinnerId: string): void
    {
        this.spinnerCache.forEach(spinner =>
        {
            if (spinner.id === spinnerId)
            {
                spinner.setShow = false;
            }
        });
    }
}
