import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })


export class AuthCoreService
{

  /**
   *
   */
  private tokentype: string;


  /**
   *
   */
  private tokenaccess: string;


  /**
   *
   */
  private tokenexpiresat: string;


  /**
   *
   */
  private islogging: boolean;


  constructor(public router: Router)
  {
    this.tokentype      = '';
    this.tokenaccess    = '';
    this.tokenexpiresat = '';
    this.islogging      = false;
  }


   /**
   *
   */
  public redirectLogin() : boolean
  {
    if (this.router.url !== '/signin')
    {
      this.router.navigate(['signin']);
    }

    return false;
  }


  /**
   *
   */
  public set tokenType(str: string)
  {
    this.tokentype = str;
  }


  /**
   *
   */
  public get tokenType(): string
  {
    return this.tokentype;
  }


    /**
   *
   */
  public set tokenAccess(str: string)
  {
    this.tokenaccess = str;
  }


  /**
   *
   */
  public get tokenAccess(): string
  {
    return this.tokenaccess;
  }


  /**
   *
   */
  public set tokenExpiresAt(str: string)
  {
    this.tokenexpiresat = str;
  }


  /**
   *
   */
  public get tokenExpiresAt(): string
  {
    return this.tokenexpiresat;
  }


  /**
   *
   */
  public set isLogging(bln: boolean)
  {
    this.islogging = bln;
  }


  /**
   *
   */
  public get isLogging(): boolean
  {
    return this.islogging;
  }


}
