import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SpinnersCoreService } from '../../services/spinners-core.service';


// https://www.chevtek.io/how-to-do-loading-spinners-the-angular-2/
@Component(
{
    selector    : 'ux-spinners',
    templateUrl : './ux-spinners.component.html',
    styleUrls   : ['./ux-spinners.component.css']
})

export class UxSpinnersComponent implements OnInit, OnDestroy
{
    @Input() id: string;
    @Input() show: boolean;
    @Input() delay: string;

    private setTimeout: number;
    private timerTimeout: number;


    constructor(private spinnersCoreService: SpinnersCoreService)
    {
        this.id    = '';
        this.show  = false;
        this.delay = '';

        this.setTimeout   = 0;
        this.timerTimeout = 1152;
    }


    ngOnInit(): void
    {
        this.timerTimeout = (!this.delay) ? this.timerTimeout : parseFloat(this.delay);

        if (!this.id) throw new Error("Spinner must have a 'id' attribute.");

        this.spinnersCoreService._register(this);
    }


    get getShow(): boolean
    {
        return this.show;
    }


    @Input()
    set setShow(val: boolean)
    {
        clearTimeout(this.setTimeout);

        if(val)
        {
            this.setTimeout = setTimeout(() =>
            {
                this.show = val;
            }, this.timerTimeout);

            console.log(this.delay, this.timerTimeout);
        }
        else this.show = val;
    }


    ngOnDestroy(): void
    {
        this.spinnersCoreService._unregister(this);
    }
}
