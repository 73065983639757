import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthCoreService } from '@app/core/services/auth-core.service';
import { GlobalService } from '@app/core/services/global.service';
import { HttpCancelCoreService } from '@app/core/services/http-cancel-core.service';
import { SpinnersCoreService } from '@app/core/services/spinners-core.service';
import { StorageCoreService } from '@app/core/services/storage.service';
import { TranslateCoreService } from '@app/core/services/translate-core.service';
import { Observable } from 'rxjs';
import { breadcrumbCoreService } from './breadcrumb-core.service';


@Injectable({ providedIn: 'root' })


/**
 *
 */
export class FacadeCoreService
{
    /**
     *
     */
    private _globalService: GlobalService;


    /**
     *
     */
    public get globalService(): GlobalService
    {
        if (!this._globalService)
        {
            this._globalService = this.injector.get(GlobalService);
        }

        return this._globalService;
    }


    /**
     *
     */
    private _translateCoreService: TranslateCoreService;


    /**
     *
     */
    public get translateCoreService(): TranslateCoreService
    {
        if (!this._translateCoreService)
        {
            this._translateCoreService = this.injector.get(TranslateCoreService);
        }

        return this._translateCoreService;
    }


    /**
     *
     */
    /*  private _spinnerCoreService : SpinnerCoreService; */


    /**
     *
     */
    /*   public get spinnerCoreService() : SpinnerCoreService
      {
        if (!this._spinnerCoreService)
        {
          this._spinnerCoreService = this.injector.get(SpinnerCoreService);
        }

        return this._spinnerCoreService;
      } */


    /**
    *
    */
    private _spinnersCoreService: SpinnersCoreService;


    /**
     *
     */
    public get spinnersCoreService(): SpinnersCoreService
    {
        if (!this._spinnersCoreService)
        {
            this._spinnersCoreService = this.injector.get(SpinnersCoreService);
        }

        return this._spinnersCoreService;
    }


    /**
     *
     */
    private _httpCancelCoreService: HttpCancelCoreService;


    /**
     *
     */
    public get httpCancelCoreService(): HttpCancelCoreService
    {
        if (!this._httpCancelCoreService)
        {
            this._httpCancelCoreService = this.injector.get(HttpCancelCoreService);
        }

        return this._httpCancelCoreService;
    }


    /**
     *
     */
    private _AuthCoreService: AuthCoreService;


    /**
     *
     */
    public get AuthCoreService(): AuthCoreService
    {
        if (!this._AuthCoreService)
        {
            this._AuthCoreService = this.injector.get(AuthCoreService);
        }

        return this._AuthCoreService;
    }


    /**
     *
     */
    private _StorageCoreService: StorageCoreService;


    /**
     *
     */
    public get StorageCoreService(): StorageCoreService
    {
        if (!this._StorageCoreService)
        {
            this._StorageCoreService = this.injector.get(StorageCoreService);
        }

        return this._StorageCoreService;
    }


    /**
    *
    */
    private _breadcrumbCoreService: breadcrumbCoreService;


    /**
     *
     */
    public get breadcrumbCoreService(): breadcrumbCoreService
    {
        if (!this._breadcrumbCoreService)
        {
            this._breadcrumbCoreService = this.injector.get(breadcrumbCoreService);
        }

        return this._breadcrumbCoreService;
    }


    /**
     *
     * @param injector
     * @param platformId
     */
    constructor(private injector: Injector, @Inject(PLATFORM_ID) private platformId: Object, private router: Router, private activatedRoute: ActivatedRoute)
    {
        this._globalService = this.globalService;
        this._translateCoreService = this.translateCoreService;
        /* this._spinnerCoreService    = this.spinnerCoreService; */
        this._spinnersCoreService = this.spinnersCoreService;
        this._httpCancelCoreService = this.httpCancelCoreService;
        this._AuthCoreService = this.AuthCoreService;
        this._StorageCoreService = this.StorageCoreService;
        this._breadcrumbCoreService = this.breadcrumbCoreService;
    }


    /**
     *
     */
    public get window(): any
    {
        return (isPlatformBrowser(this.platformId)) ? this.globalService.window : null;
    }


    /**
     *
     */
    public uiTranslate()
    {
        console.log('PLATTAFORM', (isPlatformBrowser(this.platformId)) ? 'BROWSER' : 'SERVER');
        return this.translateCoreService.uiTranslate();
    }


    /**
     *
     */
    public translate()
    {
        return this.translateCoreService.translate;
    }


    public spinnersShow(name: string): void
    {
        this.spinnersCoreService.show(name);
    }


    public spinnersHide(name: string): void
    {
        this.spinnersCoreService.hide(name);
    }


    /**
     *
     */
    /*   public spinnerShow() : void
      {
        this.spinnerCoreService.addCount();

        if (isPlatformBrowser(this.platformId))
        {
          var uxElement = document.querySelector<HTMLElement>('html');

          if (uxElement)
          {
            this.spinnerCoreService.uxElement = uxElement;
            this.spinnerCoreService.show();
          }
        }
      } */


    /**
     *
     */
    /*   public spinnerHide() : void
      {
        this.spinnerCoreService.removeCount();

        if (isPlatformBrowser(this.platformId))
        {
          if (this.spinnerCoreService.uxElement.tagName === 'HTML')
          {
            this.spinnerCoreService.hide();

            if (!this.httpCancelCoreService.isLoadComplete)
            {
              this.httpCancelCoreService.isLoadComplete = this.spinnerCoreService.isLoadComplete;
            }
          }
        }
      } */


    /**
     *
     */
    /*   public spinnerError() : void
      {
        this.spinnerCoreService.error();
      } */

    /**
     *
     */
    /*  public spinnerInit()
     {
       this.spinnerCoreService.initSpinner();
     } */


    /**
     *
     */
    /*  public spinnerReset()
     {
       this.spinnerCoreService.reset();
     } */


    /**
     *
     */
    /*  public spinnerAccountant() : Observable<any>
     {
       return this.spinnerCoreService.accountant;
     } */


    /**
     *
     */
    public canceledPendingHttpRequests(): void
    {
        this.httpCancelCoreService.canceledPendingHttpRequests();
    }


    /**
     *
     */
    public cancelPendingHttpRequests(): Observable<any>
    {
        return this.httpCancelCoreService.cancelPendingHttpRequests();
    }


    public authGetIsLogging(): boolean
    {
        return this.AuthCoreService.isLogging;
    }


    public authSetIsLogging(bln: boolean): void
    {
        this.AuthCoreService.isLogging = bln;

        if (!this.AuthCoreService.isLogging) this.authRedirectLogin();
    }


    public authRedirectLogin(): boolean
    {
        var bln = this.AuthCoreService.isLogging;

        if (!bln)
        {
            this.httpCancelCoreService.isLoadComplete = true;
            this.canceledPendingHttpRequests();
            this.AuthCoreService.redirectLogin();
        }

        return bln;
    }


    public breadcrumbInit(): void
    {
        this.breadcrumbCoreService.init();
    }


    public breadcrumbUpdate(): Observable<any>
    {
        return this.breadcrumbCoreService.update();
    }

}
