import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { UxSpinnersComponent } from '@app/core/components/ux-spinners/ux-spinners.component';

import { UiGchartComponent } from '@app/core/components/ui-analytics/ui-gchart/ui-gchart.component';
import { UiTrendingComponent } from '@app/core/components/ui-analytics/ui-trending/ui-trending.component';
import { UiBreadcrumbComponent } from '@app/core/components/ui-breadcrumb/ui-breadcrumb.component';
import { UiHeaderComponent } from '@app/core/components/ui-header/ui-header.component';

import { UiCkeditorComponent } from '@app/core/components/ui-ckeditor/ui-ckeditor.component';

import { ClickDirective } from '@app/core/directives/click.directive';
import { TranslateDirective } from '@app/core/directives/translate.directive';
import { NumberFormatDirective } from './directives/number-format.directive ';


@NgModule(
{
  imports      : [ CommonModule, RouterModule ],

  declarations :
  [
    ClickDirective,
    TranslateDirective,
    NumberFormatDirective,

    UxSpinnersComponent ,

    UiHeaderComponent,
    UiBreadcrumbComponent,

    UiCkeditorComponent,

    UiTrendingComponent,
    UiGchartComponent,
  ],

  exports :
  [
    CommonModule,
    FormsModule,

    ClickDirective,
    TranslateDirective,
    NumberFormatDirective,

    UxSpinnersComponent ,

    UiHeaderComponent,
    UiBreadcrumbComponent,

    UiCkeditorComponent,

    UiTrendingComponent,
    UiGchartComponent,
  ]
})


export class SharedCoreModule { }
