<ux-spinners id="spinner-shopping"></ux-spinners>
<ng-container *ngFor="let item of navigation; index as i; first as isFirsti; last as isLasti">
  <div class="ui-drawer" [ngClass]="(isLasti)?'ui-drawer-navigation': 'ui-drawer-nav-sub'"
    [attr.data-drawer-nav-sub]="(item.toggle)?item.toggle: null">
    <div class="ui-pad-x3 ui-drawer-nav-head">
      <button *ngIf="(!isLasti)" type="button" class="ui-btn ux-ripple" data-ux-ripple="--ui-color-failure">
        <span [attr.data-drawer-nav-sub-toggle]="item.toggle">
          <i class="mat-icon">keyboard_arrow_left</i>
        </span>
      </button>
      <div>
        <span class="ellipsis fs-large bold">{{item.title}}</span>
      </div>
    </div>
    <div class="ui-drawer-nav">
      <cdk-virtual-scroll-viewport [itemSize]="48" class="ui-scroll">
        <ng-container *cdkVirtualFor="let children of item.childrens; first as isFirstj; last as isLastj; index as j">
          <div *ngIf="(isFirstj)" class="ui-nav-subhead-hr"></div>
          <div *ngIf="(children.type === 'subhead')" [ngClass]="(children.type === 'subhead')?'ui-nav-subhead-hr':''">
            <hr />
          </div>
          <div *ngIf="(children.type === 'subhead')" class="ui-pad-x7"
            [ngClass]="(children.type === 'subhead')?'ui-nav-subhead': ''">
            <span class="ui-pad ui-nav-subhead-icon"> <i class="mat-icon"
                [ngStyle]="{'color': 'rgba(var(' + children.color + '), 1)'}">{{children.icon}}</i>
            </span>
            <span class="ui-pad ellipsis fs-medium bold" title="{{children.label}}">
              {{children.label}}
            </span>
          </div>
          <div *ngIf="(children.type === 'toggle')" class="ui-pad-x6 ux-ripple"
            [attr.data-drawer-nav-sub-toggle]="children.toggle" [attr.data-ux-ripple]="children.color">
            <span class="ui-pad ellipsis" title="{{children.label}}"> {{children.label}} </span>
            <span class="ui-pad ui-nav-subhead-icon"> <i class="mat-icon"
                [ngStyle]="{'color': 'rgba(var(' + children.color + '), 1)'}">{{children.icon}}</i> </span>
          </div>
          <div *ngIf="(children.type === 'link')" class="ux-ripple" [attr.data-ux-ripple]="children.color" [class.is-link-active]="linkActive.isActive">
            <a [routerLink]="[children.link]" [queryParams]="children.query" [routerLinkActiveOptions]="{exact: true}"
              routerLinkActive="is-link-active" class="ui-pad-x7 ellipsis" title="{{children.label}}" #linkActive="routerLinkActive">
              {{children.label}}
            </a>
          </div>
          <div *ngIf="(isLastj)" class="ui-nav-subhead-hr"></div>
        </ng-container>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</ng-container>
